/* eslint-disable no-use-before-define */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import Content from './Content';

const NavBarPropTypes = {
  onMobileClose: PropTypes.func.isRequired,
  openMobile: PropTypes.bool.isRequired,
  openNav: PropTypes.bool.isRequired,
};

type NavBarType = PropTypes.InferProps<typeof NavBarPropTypes>;

const NavBar = ({ onMobileClose, openMobile, openNav }: NavBarType) => {
  const location = useLocation();

  /*eslint-disable */
  useEffect(() => {
    if (openMobile && onMobileClose) onMobileClose();
  }, [location.pathname]);
  /*eslint-disable */

  // mobile navigation first
  // desktop navigation second
  return (
    <>
      <Drawer
        anchor="left"
        sx={{ width: 256, display: { xs: 'block', md: 'none' } }}
        open={openMobile}
        onClose={onMobileClose}
        variant="temporary"
      >
        <Content />
      </Drawer>

      <Drawer
        anchor="left"
        open={openNav}
        variant="persistent"
        sx={{
          width: 256,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Content />
      </Drawer>
    </>
  );
};

NavBar.propTypes = NavBarPropTypes;

export default NavBar;
