import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const GuestGuardPropTypes = {
  children: PropTypes.node
};

type GuestGuardTypes = PropTypes.InferProps<typeof GuestGuardPropTypes>;

const GuestGuard = ({ children }: GuestGuardTypes): JSX.Element => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/home" />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = GuestGuardPropTypes

export default GuestGuard;
