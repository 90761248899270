import { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import LoadingScreen from 'components/LoadingScreen';
import axios from 'utils/axios';
import React from 'react';

const apiUrl = process.env['REACT_APP_BACKEND_API_URL'];
console.log(apiUrl);

export type AuthContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: {
    userId: number;
    username: string;
  } | null;
  getGithubCode: () => void;
  login: (code: string) => Promise<void>;
  logout: () => void;
};

type ReducerStateType = {
  isAuthenticated: AuthContextType['isAuthenticated'];
  isInitialized: AuthContextType['isInitialized'];
  user: AuthContextType['user'];
};

type ReducerActionType =
  | {
      type: 'INITIALIZE';
      payload: {
        isAuthenticated: AuthContextType['isAuthenticated'];
        user: AuthContextType['user'];
      };
    }
  | {
      type: 'LOGIN';
      payload: {
        user: AuthContextType['user'];
      };
    }
  | { type: 'LOGOUT' };

type DecodedTokenType = {
  name: string;
  exp: number;
};

const initialAuthState: ReducerStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const isValidJWTSessionToken = (jwtSessionToken: string) => {
  if (!jwtSessionToken) {
    return false;
  }

  const decoded = jwtDecode<DecodedTokenType>(jwtSessionToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (jwtSessionToken: string | null) => {
  if (typeof jwtSessionToken === 'string') {
    localStorage.setItem('jwtSessionToken', jwtSessionToken);
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${jwtSessionToken}`;
  } else {
    localStorage.removeItem('jwtSessionToken');
    delete axios.defaults.headers.common['Authorization'];
  }
};

const reducer = (state: ReducerStateType, action: ReducerActionType) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextType>({
  ...initialAuthState,
  getGithubCode: () => {},
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const { enqueueSnackbar } = useSnackbar();

  const getGithubCode = async () => {
    try {
      window.location.replace(
        `https://github.com/login/oauth/authorize?client_id=${process.env['REACT_APP_CLIENT_ID']}&redirect_uri=${process.env['REACT_APP_OAUTH_REDIRECT_URL']}`
      );
    } catch (err: any) {
      enqueueSnackbar(err.errors[0], {
        variant: 'error',
      });
    }
  };

  const login = async (code: string) => {
    try {
      const response = await axios.post(`${apiUrl}/api/user/login`, {
        code: code,
      });
      const { jwtSessionToken, user } = response.data;

      setSession(jwtSessionToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    } catch (err: any) {
      enqueueSnackbar(err.errors[0], {
        variant: 'error',
      });
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const jwtSessionToken = window.localStorage.getItem('jwtSessionToken');

        if (jwtSessionToken && isValidJWTSessionToken(jwtSessionToken)) {
          setSession(jwtSessionToken);

          const response = await axios.get(
            `${apiUrl}/api/user/getAuthenticatedUser`
          );
          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        getGithubCode,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
