import React, { createContext, useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import {
  ClassType,
  AssignmentType,
  StudentType,
} from 'views/DashboardView/Views/SelectionView/tests/ghClassStatsTest';

const apiUrl = process.env['REACT_APP_BACKEND_API_URL'];

export interface ClassStatsContextType {
  classStats: ClassType[] | [];
  getAssignmentsOfClass: (className: string) => AssignmentType[] | undefined;
  getStudentListOfAssignment: (
    className: string,
    assignmentName: string
  ) => StudentType[] | undefined;
  gatherClassDetails: () => void;
}

type ResponseData = ClassType[];

// initial context: everything is empty and undefined
const ClassStatsContext = createContext<ClassStatsContextType>({
  classStats: [],
  getAssignmentsOfClass: () => [],
  getStudentListOfAssignment: () => [],
  gatherClassDetails: () => {},
});

const ClassStatsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [classStats, setClassStats] = useState<ClassType[]>([]);
  const { user } = useAuth();

  // use effect ir only call using a button?
  useEffect(() => {
    gatherClassDetails();
  }, []);

  // update or set class Stats
  async function gatherClassDetails() {
    const userId = user?.userId;
    await fetch(`${apiUrl}/api/githubData/gatherClassDetails`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json() as Promise<{ classStats: ResponseData }>;
      })
      .then((data) => {
        const result = data.classStats;
        setClassStats(result);
      });
  }

  const getAssignmentsOfClass = (className: string) => {
    const course = classStats.find((course) => course.className === className);
    return course ? course.assignments : [];
  };

  const getStudentListOfAssignment = (
    className: string,
    assignmentName: string
  ) => {
    return (
      classStats
        .find((course) => course.className === className)
        ?.assignments.find((a) => a.assignmentName === assignmentName)
        ?.studentList ?? []
    );
  };

  return (
    <ClassStatsContext.Provider
      value={{
        classStats,
        getAssignmentsOfClass,
        getStudentListOfAssignment,
        gatherClassDetails,
      }}
    >
      {children}
    </ClassStatsContext.Provider>
  );
};

export default ClassStatsContextProvider;
export { ClassStatsContext };
