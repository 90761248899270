export type NavDataSectionType = {
  header: string,
  items: NavDataSectionItemType[]
}

export type NavDataSectionItemType = {
  title: string, 
  href: string
}

const NavData: NavDataSectionType[] = [
  {
    header: "Dashboard",
    items: [
      {
        title: "Home",
        href: "/home",
      },
      {
        title: "Account",
        href: "/account",
      },
      {
        title: "Github Data",
        href: "/githubData",
      },
    ],
  },
];

export default NavData;
