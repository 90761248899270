import { Link, useLocation } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import Logo from 'components/Logo';
import TopBarLink from './TopBarLink';
import { THEMES } from 'theme';

const AppBarCustomized = styled(AppBar)(({ theme }) => ({
  minHeight: 64,
  zIndex: theme.zIndex.drawer + 100,
  ...(theme.name === THEMES.MAIN
    ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      }
    : {}),
})) as typeof AppBar;

const TopBarPropTypes = {
  onMobileNavOpen: PropTypes.func.isRequired,
  onClickNav: PropTypes.func.isRequired,
};

type TopBarType = PropTypes.InferProps<typeof TopBarPropTypes>;

const TopBar = ({ onMobileNavOpen, onClickNav }: TopBarType) => {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <AppBarCustomized>
      <Toolbar sx={{ minHeight: (theme) => theme.spacing(8) }}>
        <Box sx={{ display: { xs: 'block' }, mr: { sm: 2 } } }>
          <IconButton
            color="inherit"
            onClick={() => {
              onMobileNavOpen();
              onClickNav();
            }}
          >
            <SvgIcon fontSize="medium">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <Box sx={{ minHeight: (theme) => theme.spacing(0.25) }} flexGrow={1} />
        <Box sx={{ minHeight: (theme) => theme.spacing(0.25) }}>
          <TopBarLink user={user} login={location.pathname === '/login'} />
        </Box>
      </Toolbar>
    </AppBarCustomized>
  );
};

TopBar.propTypes = TopBarPropTypes;

export default TopBar;
