import {  Box, Divider,  } from "@mui/material";
import NavData, { NavDataSectionType } from "./NavData";
import NavList from "./NavList";

const Content = () => {

  return (
    <Box
      sx={{
        width: 256,
        marginTop: (theme) => theme.spacing(8),
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Divider />
      <Box p={2}>
        {NavData.map((section: NavDataSectionType) => (
          <NavList section={section}/>
        ))}
      </Box>
      <Divider />
    </Box>
  );
};

export default Content;
