import { Link, useLocation } from "react-router-dom";
import { AppBar, Box, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { THEMES } from "theme";
import Logo from "components/Logo";
import useAuth from "hooks/useAuth";
import TopBarLink from "./TopBarLink";


const AppBarCustomized = styled(AppBar)(({ theme }) => ({
  minHeight: 65,
  zIndex: theme.zIndex.drawer + 100,
  ...(theme.name === THEMES.MAIN
    ? {
        boxShadow: "none",
        backgroundColor: theme.palette.primary.main,
      }
    : {}),
})) as typeof AppBar;

const TopBar = () => {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <AppBarCustomized>
      <Toolbar>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <Box sx={{ minHeight: (theme) => theme.spacing(0.25) }} flexGrow={1} />
        <Box sx={{ minHeight: (theme) => theme.spacing(0.25) }}>
          <TopBarLink user={user} login={location.pathname === "/login"} />
        </Box>
      </Toolbar>
    </AppBarCustomized>
  );
};
export default TopBar;
