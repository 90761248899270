import { Routes, BrowserRouter } from 'react-router-dom';
import routes, { renderRoutes } from 'routes';
import { AuthProvider } from 'contexts/AuthContext';
import ClassStatsContextProvider from 'contexts/ClassStatsContext';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { createExtendedTheme } from 'theme';
import 'css/App.css';

const App = () => {
  const theme = createExtendedTheme({
    theme: 'MAIN',
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider dense maxSnack={3}>
        <BrowserRouter>
          <AuthProvider>
            <ClassStatsContextProvider>
              <Routes>{renderRoutes(routes)}</Routes>
            </ClassStatsContextProvider>
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
