import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import Account from "./Account";
import { AuthContextType } from 'contexts/AuthContext';

const LinkCustomized = styled(Link)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: "white",
  "& + &": {
    marginLeft: theme.spacing(2),
  },
})) as typeof Link;

type TopBarLinkType = { 
  user: AuthContextType["user"],
  login: boolean
}

const TopBarLink = ({ user = null, login = false }: TopBarLinkType) => {
  if (user) return <Account />;

  if (!login)
    return (
      <LinkCustomized
        color="textSecondary"
        component={RouterLink}
        to="/login"
        underline="none"
        variant="h4"
      >
        Login
      </LinkCustomized>
    );

  return (
    <>     
    </>
  );
};

export default TopBarLink;
