import { createTheme } from "@mui/material/styles";
import typography from "./typography";

declare module "@mui/material/styles" {
  interface Theme {
    name: string
  }
}

export const THEMES = {
  MAIN: "MAIN",
};

const baseTheme = createTheme({
  typography,
});

const themeOptions = [
  {
    name: THEMES.MAIN,
    palette: {
      type: "light",
      background: {
        default: "#E8EAF6",
        dark: "#C5CAE9",
        paper: "#fff",
      },
      primary: {
        main: "#3949ab",
        dark: "#3949ab",
      },
      secondary: {
        main: "#5850EC",
        dark: "#5850EC",
      },
      text: {
        primary: "#263238",
        secondary: "#546e7a",
      },
    },
  },
];

export type ThemeType = typeof baseTheme;

type ConfigType = {
  theme?: string
}

export const createExtendedTheme = (config: ConfigType): ThemeType => {
  let selectedOption = themeOptions.find(
    (theme) => theme.name === config.theme
  );

  if (!selectedOption) {
    console.warn(
      new Error(`The provided theme name ${config.theme} is not valid`)
    );
    selectedOption = themeOptions[0];
  }

  if (typeof selectedOption !== "undefined") {
    return createTheme(baseTheme, selectedOption);
  } else {
    console.warn(
      new Error(`There is no substitue theme available`)
    );
    return baseTheme;
  }
};
